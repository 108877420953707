import './App.css';

import { Route, Routes, Navigate, BrowserRouter } from 'react-router-dom';
import {
    GoogleReCaptchaProvider,
    useGoogleReCaptcha
} from 'react-google-recaptcha-v3';

import Questions from "./Questions/Questions";

function App() {
    return (
        <BrowserRouter>
            <div className="App" id="intro-container">
                <div style={{ minHeight: "85vh" }}>
                    <Routes>
                        <Route path="/*" element={<Navigate to="/pain_fact" />} />
                        <Route path="/pain_fact" element={
                            <GoogleReCaptchaProvider reCaptchaKey="6LcSEywjAAAAAOf7243vWqZE41HOgmXRQCUeYbMT">
                                <Questions />
                            </GoogleReCaptchaProvider>} />
                    </Routes>
                </div>
                <footer>
                    © {new Date().getFullYear()} Þjónustumiðstöð rannsóknaverkefna. Allur réttur áskilinn.
                </footer>
            </div>
        </BrowserRouter>

    );
}

export default App;
