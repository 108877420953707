import React from "react";
import {CSSTransition, SwitchTransition} from "react-transition-group";
import {
    AppBar,
    Button,
    createTheme,
    Grid,
    responsiveFontSizes,
    TextField,
    ThemeProvider,
    Toolbar,
    Typography
} from "@mui/material";
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import SendIcon from '@mui/icons-material/Send';

import {useGoogleReCaptcha} from 'react-google-recaptcha-v3';

// Styles
import "./TransitionAnimation.css";

import logo from "./thjonustumidstod-banner-2016.jpg";
import Swal from "sweetalert2";
import axios from "axios";
import {Box} from "@mui/system";

let theme = createTheme();
theme = responsiveFontSizes(theme);


export default () => {
    const [question, setQuestion] = React.useState(0);
    const [answeredNo, setAnsweredNo] = React.useState(false);
    const [finishedProcess, setFinishedProcess] = React.useState(false);

    const [name, setName] = React.useState("");
    const [kt, setKt] = React.useState("");
    const [telNumber, setTelNumber] = React.useState("");
    const [postcode, setPostcode] = React.useState("");

    const { executeRecaptcha } = useGoogleReCaptcha();

    // Create an event handler so you can call the verification on button click event or form submit
    const handleReCaptchaVerify = React.useCallback(async () => {
        if (!executeRecaptcha) {
            console.log('Execute recaptcha not yet available');
            return;
        }

        const token = await executeRecaptcha('yourAction');
        return token;
        // Do whatever you want with the token
    }, [executeRecaptcha]);

    // You can use useEffect to trigger the verification as soon as the component being loaded
    React.useEffect(() => {
        handleReCaptchaVerify();
    }, [handleReCaptchaVerify]);

    const questions = [
        {
            text: "Ertu með sár, sýkingu eða útbrot á höndum eða fótum?",
            proceedAnswer: false
        },
        {
            text: "Ertu með umbúðir eða gifs eða annað sem gerir hendur eða fætur óaðgengilega fyrir mælingu?",
            proceedAnswer: false
        },
        {
            text: "Ertu með nýleg meiðsli eða aðgerð (innan 6 mánaða) á höndum eða fótum?",
            proceedAnswer: false
        },
        {
            text: "Ertu með nýlega verki, bólgur eða bjúg (innan 3 mánaða) á höndum eða fótum?",
            proceedAnswer: false
        },
        {
            text: "Ertu með sögu um æðahnúta, blóðtappa eða lélegt blóðflæði í fótum?",
            proceedAnswer: false
        },
        {
            text: "Ertu með sögu um alvarlegan háþrýsting eða langvarandi hjartaveikindi?",
            proceedAnswer: false
        },
    ];

    const userAnswersYes = () => {
        if (questions[question].proceedAnswer === true) {
            setQuestion(question + 1)
        } else {
            setAnsweredNo(true);
        }
    }

    const userAnswersNo = () => {
        if (questions[question].proceedAnswer === false) {
            setQuestion(question + 1)
        } else {
            setAnsweredNo(true);
        }
    }

    const handleEnter = (e, textFieldNo) => {
        if (e.key === "Enter") {
            if (textFieldNo === 5) {
                setTimeout(() => document.getElementById("sendButton").focus(), 100);
            } else if (e.target.value.length === 0) {
                setTimeout(() => Swal.fire({
                    title: "Tómur dálkur",
                    text: 'Þú þarft að setja inn texta',
                    icon: "error",
                    confirmButtonText: 'Áfram',
                    showCancelButton: false,
                }), 100);
            } else if (textFieldNo === 2) {
                if (isValid(e.target.value)) {
                    confirmSSN();
                } else {
                    setTimeout(() => Swal.fire({
                        title: "Röng kennitala",
                        text: 'Þú þarft að setja inn rétta kennitölu',
                        icon: "error",
                        confirmButtonText: 'Áfram',
                        showCancelButton: false,
                    }), 100);
                }
            } else if (textFieldNo === 3 && e.target.value.replace(/\D/g, '').length !== 7) {
                setTimeout(() => Swal.fire({
                    title: "Rangt símanúmer",
                    text: 'Þú þarft að setja inn gilt íslenskt símanúmer',
                    icon: "error",
                    confirmButtonText: 'Áfram',
                    showCancelButton: false,
                }), 100);
            } else if (textFieldNo === 4) {
                if (e.target.value.length !== 3 || isNaN(Number(e.target.value))) {
                    setTimeout(() => Swal.fire({
                        title: "Rangt póstnúmer",
                        text: 'Þú þarft að setja inn gilt íslenskt póstnúmer',
                        icon: "error",
                        confirmButtonText: 'Áfram',
                        showCancelButton: false,
                    }), 100);
                }
                else if (Number(e.target.value) > 320 && (Number(e.target.value) < 800 || Number(e.target.value) >= 846)) {
                    setTimeout(() => Swal.fire({
                        title: "<strong>Skráð búseta langt frá rannsóknarstað</strong>",
                        icon: "info",
                        html: "<p>Vinsamlega athugið að rannsóknin fer einungis fram í húsnæði Þjónustumiðstöðvar rannsóknaverkefna í Turninum við Smáratorg í Kópavogi. Þátttakendur munu þurfa að koma þangað til að taka þátt.</p>",
                        showCloseButton: true,
                        showCancelButton: true,
                        focusConfirm: false,
                        confirmButtonColor: "#21b6ae",
                        cancelButtonColor: "#931621",
                        confirmButtonText: "<p style='color: white'>Staðfesta</p>",
                        cancelButtonText: "<p style='color: white'>Hætta við</p>"
                    }).then((result) => {
                        console.log(result)
                        if (result.isConfirmed) {
                            sendForm();
                        } else if (result.dismiss === "cancel") {
                            setAnsweredNo(true);
                        }
                    }), 100);
                }
                else {
                    sendForm();
                }
            } else {
                let newIndex = textFieldNo + 1;
                setTimeout(() => {
                    const elem = document.getElementById("textField" + newIndex);
                    if (elem) elem.focus();
                }, 100);
            }
        }
    }

    const confirmSSN = async () => {
        try {
            let token = await handleReCaptchaVerify();
            let result = await axios.post("/api/confirm-ssn", { ssn: kt, token: token });
            setName(result.data.name)
        } catch (ex) {
            console.log(ex.response.data.error === "invalid")
            if (ex.response && ex.response.data && ex.response.data.error && ex.response.data.error === "invalid") {
                Swal.fire({
                    title: "Ógild aðgerð",
                    text: 'Vinsamlegast reyndu aftur eða hafðu samband við Þjónustumiðstöð rannsóknaverkefna í síma 520 2800',
                    icon: "error",
                    confirmButtonText: 'Áfram',
                    showCancelButton: false,
                })
            }
            else {
                Swal.fire({
                    title: "Röng kennitala",
                    text: 'Vinsamlegast athugaðu hvort kennitalan sé rétt skrifuð',
                    icon: "error",
                    confirmButtonText: 'Áfram',
                    showCancelButton: false,
                })
            }
        }
    }

    const verifyForm = () => {
        if (!kt || kt.length === 0) {
            Swal.fire({
                title: "Kennitölu vantar",
                text: 'Þú þarft að fylla inn kennitölu',
                icon: "error",
                confirmButtonText: 'Áfram',
                showCancelButton: false,
            })
        } else if (!isValid(kt)) {
            Swal.fire({
                title: "Röng kennitala",
                text: 'Þú þarft að setja inn rétta kennitölu',
                icon: "error",
                confirmButtonText: 'Áfram',
                showCancelButton: false,
            })
        } else if (!telNumber || telNumber.replace(/\D/g, '').length !== 7) {
            Swal.fire({
                title: "Rangt símanúmer",
                text: 'Þú þarft að fylla inn rétt íslenskt símanúmer',
                icon: "error",
                confirmButtonText: 'Áfram',
                showCancelButton: false,
            })
        } else if (!postcode || postcode.length !== 3) {
            Swal.fire({
                title: "Póstnúmer vantar",
                text: 'Þú þarft að fylla inn rétt íslenskt póstnúmer',
                icon: "error",
                confirmButtonText: 'Áfram',
                showCancelButton: false,
            })
        }
        else if (postcode > 320 && (postcode < 800 || postcode >= 846)) {
            Swal.fire({
                title: "<strong>Skráð búseta langt frá rannsóknarstað</strong>",
                icon: "info",
                html: "<p>Vinsamlega athugið að rannsóknin fer einungis fram í húsnæði Þjónustumiðstöðvar rannsóknaverkefna í Turninum við Smáratorg í Kópavogi. Þátttakendur munu þurfa að koma þangað til að taka þátt.</p>",
                showCloseButton: true,
                showCancelButton: true,
                focusConfirm: false,
                confirmButtonColor: "#21b6ae",
                cancelButtonColor: "#931621",
                confirmButtonText: "<p style='color: white'>Staðfesta</p>",
                cancelButtonText: "<p style='color: white'>Hætta við</p>"
            }).then((result) => {
                if (result.isConfirmed) {
                    sendForm();
                } else if (result.dismiss === "cancel") {
                    setAnsweredNo(true);
                }
            })
        }
        else {
            sendForm();
        }
    }

    const sendForm = () => {
        axios.post("/api/add-new-participant", {
            name: name,
            ssn: kt,
            phoneNumber: telNumber,
            urlPath: window.location.pathname.replace("/", "")
        }).then(res => {
            setFinishedProcess(true);
        }).catch(err => {
            console.log(err);
            Swal.fire({
                title: "Skráning mistókst",
                text: 'Reyndu aftur síðar',
                icon: "error",
                confirmButtonText: 'Áfram',
                showCancelButton: false,
            })
        })
    }

    // Kennitölucheck
    const isMod11 = (kt) => {
        // Modulus-aðferð við að sannreyna kennitölu
        // https://www.skra.is/einstaklingar/eg-og-fjolskyldan/eg-i-thjodskra/um-kennitolur/
        const mod11 =
            11 -
            ((3 * Number(kt.charAt(0)) +
                2 * Number(kt.charAt(1)) +
                7 * Number(kt.charAt(2)) +
                6 * Number(kt.charAt(3)) +
                5 * Number(kt.charAt(4)) +
                4 * Number(kt.charAt(5)) +
                3 * Number(kt.charAt(6)) +
                2 * Number(kt.charAt(7))) %
                11)
        if (mod11 === 11 && kt.charAt(8) === '0') {
            return true
        }
        if (mod11 !== Number(kt.charAt(8))) {
            return false
        }
        return true
    }

    const clean = kt => {
        kt = kt || ''
        if (typeof kt === 'number') {
            kt = kt.toString()
        }
        if (kt) {
            kt = kt.replace(/(\D)+/g, '')
        }
        if (kt.length === 9) {
            kt = '0' + kt // Stutt kennitala (eða ólögleg), bætum einu núlli fyrir framan.
        }
        return kt
    }

    const isValid = (kt) => {
        kt = clean(kt)
        if (
            typeof kt !== 'string' ||
            kt.length < 9 ||
            !kt.match(/^[\d.]/) ||
            kt === '0000000000' ||
            Number(kt.substr(kt.length - 8, 2)) > 13 ||
            Number(kt.substr(kt.length - 8, 2)) === 0
        ) {
            return false
        }
        return isMod11(kt)
    }


    return (
        <ThemeProvider theme={theme}>
            <div>
                <AppBar position="static" style={{background: 'transparent', height: 80}}>
                    <Toolbar>
                        <img src={logo} alt="Merki þjónustumiðstöðvar rannsóknaverkefna"
                             style={{margin: "3vh", width: "80vw", maxWidth: "520px"}}/>
                    </Toolbar>
                </AppBar>


                {question !== questions.length && !answeredNo && (
                    <div style={{width: "90%", margin: "0 auto", paddingTop: "20px"}}>
                        <Typography variant="body1">
                            Rannsókn á erfðum verkja - sársaukanæmi og sársaukaþol
                        </Typography>
                    </div>
                )}
                {answeredNo && (
                    <div style={{marginTop: "20vh", marginBottom: "20vh"}}>
                        <Grid container justifyContent="center">
                            <Grid item xs={12} sm={6} align="center">
                                <Typography style={{marginBottom: "6px"}} variant="body1">
                                    Takk fyrir áhugann.
                                </Typography>
                                <Typography style={{marginBottom: "6px"}} variant="body1">
                                    Þú ættir ekki að taka þátt í mælingunum en við hvetjum þig til að svara stuttum
                                    spurningalista
                                    um sársaukanæmi og fá niðurstöður um sársaukanæmi þitt borið saman við rúmlega 5
                                    þúsund
                                    Íslendinga.
                                </Typography>
                                <Typography style={{marginBottom: "6px"}} variant="body1">
                                    Smelltu hér til að svara spurningalistanum:
                                </Typography>
                                <Typography variant="body1">
                                    <a href="https://svipgerd.is/q/pain-fact-c/">
                                        Spurningalisti um sársaukanæmi
                                    </a>
                                </Typography>
                            </Grid>
                        </Grid>
                    </div>
                )}
                {finishedProcess && (
                    <Grid container justifyContent="center">
                        <Grid item xs={12} sm={6} align="center">
                            <div style={{marginTop: "20vh", marginBottom: "20vh"}}>
                                <h3>Takk fyrir áhugann.</h3>
                                <Typography variant="body1">
                                    Skráningin hefur verið send til Þjónustumiðstöðvar rannsóknaverkefna og fulltrúi
                                    frá þeim mun hafa samband við þig fljótlega.
                                </Typography>
                                <Typography variant="body1">
                                    Ef þú hefur ekki gert það nú þegar þá bjóðum við þér einnig að
                                    svara stuttum spurningalista um sársaukanæmi og fá að vita
                                    hvar þú mælist í samanburði við 5 þúsund aðra Íslendinga.
                                </Typography>
                                <Typography variant="body1">
                                    <a href="https://svipgerd.is/q/pain-fact-c/">Spurningalisti</a>
                                </Typography>
                            </div>
                        </Grid>
                    </Grid>
                )}
                {!finishedProcess && !answeredNo && (
                    <form onSubmit={(e) => e.preventDefault()} style={{overflowX: "hidden"}}>
                        <Grid container justifyContent="center" spacing={3}
                              sx={{
                                  margin: "0 auto",
                                  mt: 6,
                                  mb: 4,
                                  minHeight: theme.spacing(16),
                                  maxWidth: "80vw",
                              }}
                        >
                            <SwitchTransition mode={"out-in"}>
                                <CSSTransition
                                    key={question}
                                    addEndListener={(node, done) => {
                                        node.addEventListener("transitionend", done, false);
                                    }}
                                    classNames="fade">
                                    <div className="button-container">
                                        {question < questions.length &&
                                            <div className="btn">
                                                <Typography variant="h4">
                                                    {questions[question].text}
                                                </Typography>
                                            </div>
                                        }
                                        {question >= questions.length && (
                                            <Grid container justifyContent="center">
                                                <Grid item xs={12} sm={6} align="center">
                                                    <div className="btn">
                                                        <Typography variant="h4">
                                                            {"Þakka þér fyrir sýndan áhuga á þátttöku í rannsókninni!"}
                                                        </Typography>
                                                        <p>Þú getur skráð þig hér og þá mun
                                                            rannsóknarhjúkrunarfræðingur
                                                            eða
                                                            starfsmaður rannsóknarinnar
                                                            hafa samband við þig fljótlega til að ræða frekar við
                                                            þig um
                                                            hæfi þitt til að taka þátt í rannsókninni og veita þér
                                                            nánari
                                                            upplýsingar.
                                                        </p>

                                                        {name && (
                                                            <TextField label="Nafn" id="textField1" value={name}
                                                                       disabled
                                                                       variant="outlined"
                                                                       style={{width: "100%", paddingBottom: 10}}/>
                                                        )}
                                                        <TextField label="Kennitala" id="textField2" autoFocus={!name}
                                                                   onKeyDown={(e) => handleEnter(e, 2)}
                                                                   value={kt}
                                                                   disabled={name.length > 0}
                                                                   variant="outlined"
                                                                   onChange={e => setKt(e.target.value)}
                                                                   style={{width: "100%", paddingBottom: 10}}/>
                                                        {name && (
                                                            <>
                                                                <hr/>
                                                                <br/>
                                                                <TextField label="Símanúmer" id="textField3"
                                                                           onKeyDown={(e) => handleEnter(e, 3)}
                                                                           autoFocus
                                                                           value={telNumber}
                                                                           onChange={e => setTelNumber(e.target.value)}
                                                                           variant="outlined"
                                                                           style={{width: "100%", paddingBottom: 10}}/>
                                                                <TextField label="Póstnúmer búsetu" id="textField4"
                                                                           onKeyDown={(e) => handleEnter(e, 4)}
                                                                           value={postcode}
                                                                           onChange={e => setPostcode(e.target.value)}
                                                                           variant="outlined"
                                                                           style={{width: "100%", marginBottom: 20}}/>
                                                            </>
                                                        )}
                                                        <Button style={{
                                                            borderRadius: 35,
                                                            marginTop: 10,
                                                            backgroundColor: name.length > 0 ? "#21b6ae" : "#21b6ae",
                                                            padding: "18px 40%",
                                                            fontSize: "18px",
                                                            maxWidth: "80vw"
                                                        }}
                                                                variant="contained" id="sendButton" color="primary"
                                                                onClick={() => name.length > 0 ? verifyForm() : confirmSSN()}>
                                                            <SendIcon
                                                                style={{marginRight: 20}}/> {name.length > 0 ? "Senda" : "Áfram"}
                                                        </Button>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        )}
                                    </div>
                                </CSSTransition>
                            </SwitchTransition>
                        </Grid>
                        {question < questions.length && (
                            <>

                                <Box sx={{display: {sm: 'none', xs: 'block'}}}>
                                    <Grid container>
                                        <Grid item xs={12} sm={6}>
                                            <Button style={{
                                                borderRadius: 35,
                                                backgroundColor: "#21b6ae",
                                                padding: "18px 35%",
                                                fontSize: "18px"
                                            }} variant="contained" color="primary" onClick={userAnswersYes}><ThumbUpIcon
                                                style={{marginRight: 20}}/> Já</Button>
                                        </Grid>
                                        <Grid item xs={12} sm={6} style={{marginTop: 30}}>
                                            <Button style={{
                                                borderRadius: 35,
                                                backgroundColor: "#931621",
                                                padding: "18px 35%",
                                                fontSize: "18px"
                                            }} variant="contained" color="primary"
                                                    onClick={userAnswersNo}><ThumbDownIcon
                                                style={{marginRight: 20}}/> Nei</Button>
                                        </Grid>
                                    </Grid>
                                </Box>

                                <Box sx={{display: {xs: "none", sm: "block"}}}>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <Button style={{
                                                borderRadius: 35,
                                                backgroundColor: "#21b6ae",
                                                padding: "18px 100px",
                                                fontSize: "18px"
                                            }} variant="contained" color="primary" onClick={userAnswersYes}><ThumbUpIcon
                                                style={{marginRight: 20}}/> Já</Button>
                                            <Button style={{
                                                borderRadius: 35,
                                                marginLeft: 30,
                                                backgroundColor: "#931621",
                                                padding: "18px 100px",
                                                fontSize: "18px"
                                            }} variant="contained" color="primary"
                                                    onClick={userAnswersNo}><ThumbDownIcon
                                                style={{marginRight: 20}}/> Nei</Button>
                                        </Grid>
                                    </Grid>
                                </Box>

                                <hr className="style-two" style={{width: "50%", marginTop: 30, marginBottom: 20}}/>

                                <Grid container justifyContent="center">
                                    <Grid item xs={11} sm={6} align="center">
                                        <Typography variant="body1">
                                            Hægt er að nálgast nánari upplýsingar um rannsóknina
                                            á <a href="https://www.rannsokn.is/verkir/"
                                                 target="_blank">www.rannsokn.is/verkir</a>
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </>
                        )}
                    </form>)}
            </div>
        </ThemeProvider>
    );
}
